<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FED159;"
      d="M277.165,356.376V258.79c61.871-10.111,109.091-63.804,109.091-128.535
	C386.254,58.318,327.938,0,255.999,0S125.744,58.318,125.744,130.255c0,64.73,47.22,118.423,109.091,128.535V512h42.33v-22.41
	h38.595h24.9v-43.575h-63.495v-46.064h63.495v-43.575h-24.9L277.165,356.376L277.165,356.376z"
    />
    <circle style="fill:#F6C454;" cx="255.999" cy="130.261" r="96.431" />
    <polygon
      style="fill:#FED159;"
      points="306.033,93.044 306.033,71.832 266.605,71.832 266.605,44.508 245.393,44.508 
	245.393,71.832 205.966,71.832 205.966,140.861 284.822,140.861 284.822,167.467 205.966,167.467 205.966,188.678 245.393,188.678 
	245.393,216.002 266.605,216.002 266.605,188.678 306.033,188.678 306.033,119.65 227.177,119.65 227.177,93.044 "
    />
    <g>
      <rect
        x="234.838"
        y="258.787"
        style="fill:#F6C454;"
        width="11.9"
        height="253.213"
      />
      <rect
        x="277.161"
        y="477.763"
        style="fill:#F6C454;"
        width="63.498"
        height="11.827"
      />
      <rect
        x="277.161"
        y="388.127"
        style="fill:#F6C454;"
        width="63.498"
        height="11.827"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
